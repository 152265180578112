import { ChainId, Currency, CurrencyAmount, currencyEquals, ETHER, JSBI, Token } from '@safemoon/sdk'
import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens } from '../../hooks/Tokens'
import { useDefaultTokenList } from '../../state/lists/hooks'
import { ExendToken, useAddUserToken, useRemoveUserAddedToken } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { ButtonPrimary, ButtonSecondary } from '../Button'
import Column, { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { getContract, isDefaultToken } from '../../utils'
import Tooltip from '../Tooltip'
import { isMobile } from 'react-device-detect'
import { getNativeSymbol } from '../../utils/getTokenSymbol'
import { IInactiveToken, IType } from '../../interfaces/Tokens'
import { ERC20_ABI } from '../../constants/abis/erc20'
import CurrencyLogoAdded from '../CurrencyLogo/CurrencyLogoAdded'

function currencyKey(currency: Currency): string {
  return (currency as any)?.address || (currency === ETHER ? 'ETHER' : '')
}

const NativeTokenIntro = ({ chainId }: { chainId: number }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[6px]">{getNativeSymbol(chainId)}</p>
    <p className="text-[13px] leading-[16px] label-text2 mb-0">
      This is the native coin on this blockchain. This coin is used to pay the network fees for every transaction.
    </p>
  </div>
)

const WrappedNativeTokenIntro = ({ symbol }: { symbol: string }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[6px]">{symbol}</p>
    <p className="text-[13px] leading-[16px] label-text2 mb-0">
      This is the wrapped version of the native coin. You can use it to swap, but you cannot pay gas fees with it. You
      may unwrap it to native coin here. Blockchain gas fees will still be required for this action.
    </p>
  </div>
)

const SFMTokenIntro = ({ chainId }: { chainId: number }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[10px]">
      SFM{' '}
      {chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
        ? '(BSC)'
        : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
        ? '(Polygon)'
        : chainId === ChainId.ARB_TESTNET || chainId === ChainId.ARB_MAINNET
        ? '(Arbitrum)'
        : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
        ? '(Avalanche)'
        : chainId === ChainId.BASE_SEPOLIA || chainId === ChainId.BASE_MAINNET
        ? '(Base)'
        : chainId === ChainId.LINEA
        ? '(Linea)'
        : chainId === ChainId.FANTOM
        ? '(Fantom)'
        : chainId === ChainId.OPTIMISM
        ? '(Optimism)'
        : chainId === ChainId.BLAST
        ? '(Blast)'
        : '(ETH)'}
    </p>
    <div className="flex items-center justify-between mb-[15px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2">Date Listed</p>
      <p className="mb-0 text-[13px] leading-[13px] secondary-text">
        {chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET ? '12/12/2021' : '3/29/2023'}
      </p>
    </div>
    <div className="mb-[10px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2 mb-[8px]">Tokenomics</p>

      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Buy</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Sell</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Transfer</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
      </div>
    </div>
    {/* <p className="text-[13px] leading-[20px] label-text2 mb-[10px]">0.5% Token and 0.5% {getNativeSymbol(chainId)}</p> */}
    <p className="mb-[10px] text-[13px] leading-[13px] label-text2">Social Media</p>
    <div className="flex items-center mb-[20px]">
      <a className="mr-[10px] cursor-pointer" href={'https://safemoon.com'} target="_blank" rel="noopener noreferrer">
        <img src="/images/Internet.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://twitter.com/safemoon'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/Twitter.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://discord.com/invite/safemoon'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/discord.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://www.facebook.com/SafeMoonOfficial/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/facebook.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://www.reddit.com/r/SafeMoon/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/reddit.svg" className="w-[24px]" alt="icon" />
      </a>
    </div>
    <a className="link text-underline" href={`https://safemoon.com`} target="_blank" rel="noopener noreferrer">
      Click to learn more
    </a>
  </div>
)

const TokenIntro = ({ currency }: { currency: any }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[10px]">{currency?.symbol}</p>
    {(currency?.tokenInfo?.listingDate || currency?.tokenInfo?.partnerData?.listingDate) && (
      <div className="flex items-center justify-between mb-[15px]">
        <p className="mb-0 text-[13px] leading-[13px] label-text2">Date Listed</p>
        <p className="mb-0 text-[13px] leading-[13px] secondary-text">
          {moment(
            currency?.tokenInfo?.listingDate || currency?.tokenInfo?.partnerData?.listingDate,
            'MM/DD/YYYY'
          ).format('MM/DD/YYYY')}
        </p>
      </div>
    )}
    <div className="mb-[15px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2 mb-[8px]">Tokenomics</p>

      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Buy</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.actualBuyFee || 0}%
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Sell</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.actualSellFee || 0}%
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Transfer</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.transferFee || 0}%
          </p>
        </div>
      </div>
    </div>
    {(currency?.tokenInfo?.partnerData?.website ||
      currency?.tokenInfo?.partnerData?.twitter ||
      currency?.tokenInfo?.partnerData?.telegram ||
      currency?.tokenInfo?.partnerData?.discord ||
      currency?.tokenInfo?.partnerData?.facebook ||
      currency?.tokenInfo?.partnerData?.redis ||
      currency?.tokenInfo?.website ||
      currency?.tokenInfo?.twitter ||
      currency?.tokenInfo?.telegram ||
      currency?.tokenInfo?.discord ||
      currency?.tokenInfo?.facebook ||
      currency?.tokenInfo?.redis) && (
      <>
        <p className="mb-[10px] text-[13px] leading-[13px] label-text2">Social Media</p>
        <div className="flex items-center mb-[20px]">
          {(currency?.tokenInfo?.website || currency?.tokenInfo?.partnerData?.website) && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.website || currency?.tokenInfo?.partnerData?.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Internet.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
          {(currency?.tokenInfo?.twitter || currency?.tokenInfo?.partnerData?.twitter) && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.twitter || currency?.tokenInfo?.partnerData?.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Twitter.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
          {(currency?.tokenInfo?.discord || currency?.tokenInfo?.partnerData?.discord) && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.discord || currency?.tokenInfo?.partnerData?.discord}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/discord.svg" className="w-[24px]" alt="icon" />
            </a>
          )}

          {(currency?.tokenInfo?.facebook || currency?.tokenInfo?.partnerData?.facebook) && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.facebook || currency?.tokenInfo?.partnerData?.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/facebook.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
          {(currency?.tokenInfo?.redis || currency?.tokenInfo?.partnerData?.redis) && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.redis || currency?.tokenInfo?.partnerData?.redis}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/reddit.svg" className="w-[24px]" alt="icon" />
            </a>
          )}

          {(currency?.tokenInfo?.telegram || currency?.tokenInfo?.partnerData?.telegram) && (
            <a
              className="cursor-pointer"
              href={currency?.tokenInfo?.telegram || currency?.tokenInfo?.partnerData?.telegram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Telegram.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
        </div>
      </>
    )}

    {(currency?.tokenInfo?.learnMore || currency?.tokenInfo?.partnerData?.learnMore) && (
      <a
        className="link text-underline"
        href={currency?.tokenInfo?.learnMore || currency?.tokenInfo?.partnerData?.learnMore}
        target="_blank"
        rel="noopener noreferrer"
      >
        Click to learn more
      </a>
    )}
  </div>
)

function CurrencyRow({ index, style, data }: any) {
  const {
    currencies,
    defaultTokens,
    allTokens,
    ETHBalance,
    selectedCurrency,
    allBalances,
    onCurrencySelect,
    otherCurrency,
    chainId,
    theme,
    showSendWithSwap,
    account,
    removeToken,
    addToken,
    fromCustom
  } = data
  const [showTooltip, setShowTooltip] = useState(false)
  const currency = currencies[index]
  const key = currencyKey(currency)
  const isDefault = isDefaultToken(defaultTokens, currency)
  const customAdded = Boolean(!isDefault && currency instanceof Token && allTokens[currency.address])
  const balance = currency === ETHER ? ETHBalance : allBalances[key]

  const zeroBalance = balance && JSBI.equal(JSBI.BigInt(0), balance.raw)

  const isSelected = Boolean(selectedCurrency && currencyEquals(currency, selectedCurrency))
  const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))

  const open = useCallback(() => setShowTooltip(true), [setShowTooltip])
  const close = useCallback(() => setShowTooltip(false), [setShowTooltip])

  return currency?.type === 'label' ? (
    <MenuItem style={style} className={`token-item-${key}`} disabled={isSelected} selected={otherSelected}>
      <div className="flex items-center w-full">
        <div className="color-white background-main py-[10px] text-[13px] rounded-[6px] px-[16px] w-full">
          Expanded results from inactive Token Lists
        </div>
      </div>
    </MenuItem>
  ) : currency?.fromSearch ? (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      disabled={isSelected}
      selected={otherSelected}
      onMouseEnter={!isMobile ? open : undefined}
      onMouseLeave={!isMobile ? close : undefined}
      onMouseOver={!isMobile ? open : undefined}
    >
      <div className="flex items-center justify-between flex-1">
        <RowFixed>
          {currency.logoURI ? (
            <img src={currency.logoURI} className="w-[24px] mr-[15px] opacity-[0.6]" />
          ) : (
            <CurrencyLogoAdded symbol={currency.symbol} size="20px" className="mr-[15px] opacity-[0.6] text-[16px]" />
          )}

          <Column className="opacity-[0.6]">
            <Text fontWeight={700} color={theme.text6} fontSize={16}>
              {currency?.symbol !== 'ETH' || currency?.address ? currency?.symbol : getNativeSymbol(chainId)}
            </Text>
          </Column>
        </RowFixed>
        <AutoColumn>
          <ButtonPrimary height={'32px'} onClick={() => addToken(currency)}>
            Import
          </ButtonPrimary>
        </AutoColumn>
      </div>
    </MenuItem>
  ) : (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      disabled={isSelected}
      selected={otherSelected}
      onMouseEnter={!isMobile ? open : undefined}
      onMouseLeave={!isMobile ? close : undefined}
      onMouseOver={!isMobile ? open : undefined}
    >
      <div
        className="flex items-center justify-between flex-1"
        onClick={() => (isSelected ? null : onCurrencySelect(currency))}
      >
        <RowFixed>
          <CurrencyLogo currency={currency} size={'24px'} style={{ marginRight: '15px' }} />

          <Column>
            <Text fontWeight={700} color={theme.text6} fontSize={16}>
              {currency?.symbol !== 'ETH' || currency?.address ? currency?.symbol : getNativeSymbol(chainId)}
            </Text>
            <FadedSpan>
              {customAdded && !fromCustom ? (
                <TYPE.main fontWeight={500}>
                  Added by user
                  {/* <LinkStyledButton
                    onClick={event => {
                      event.stopPropagation()
                      if (currency instanceof Token) removeToken(chainId, currency.address)
                    }}
                  >
                    (Remove)
                  </LinkStyledButton> */}
                </TYPE.main>
              ) : null}
              {!isDefault && !customAdded ? (
                <TYPE.main fontWeight={500}>
                  Found by address
                  <LinkStyledButton
                    onClick={event => {
                      event.stopPropagation()
                      if (currency instanceof Token) addToken(currency)
                    }}
                  >
                    (Add)
                  </LinkStyledButton>
                </TYPE.main>
              ) : null}
            </FadedSpan>
          </Column>
        </RowFixed>
        <AutoColumn className="flex-1 pl-[10px] break-all">
          {balance ? (
            <Text color={theme.inputColor} fontSize={20} fontWeight={700} textAlign={'right'}>
              {zeroBalance && showSendWithSwap ? (
                <ButtonSecondary padding={'4px 8px'}>
                  <Text textAlign="center" fontWeight={500} fontSize={14} color={theme.primary1}>
                    Send With Swap
                  </Text>
                </ButtonSecondary>
              ) : balance ? (
                `${balance.toSignificant(6)}`
              ) : (
                '-'
              )}
            </Text>
          ) : account ? (
            <Loader />
          ) : (
            '-'
          )}
        </AutoColumn>
      </div>
      {!customAdded ? (
        <Tooltip
          component={
            currency === ETHER ? (
              <NativeTokenIntro chainId={chainId} />
            ) : currency?.symbol === 'WBNB' ||
              currency?.symbol === 'WETH' ||
              currency?.symbol === 'WMATIC' ||
              currency?.symbol === 'WPOL' ? (
              <WrappedNativeTokenIntro symbol={currency?.symbol} />
            ) : (
              <TokenIntro currency={currency} />
            )
          }
          show={showTooltip}
        >
          <div
            className={classNames('ml-[10px] md:ml-[40px]', showTooltip ? 'opacity-[1]' : 'opacity-[0.5]')}
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onMouseEnter={open}
            onMouseLeave={close}
          >
            <img src="/images/info.svg" className="w-[24px]" alt="icon" />
          </div>
        </Tooltip>
      ) : fromCustom ? (
        <a
          className="ml-[10px] md:ml-[40px] opacity-[0.5] hover:opacity-[1]"
          onClick={() => removeToken(chainId, currency.address)}
        >
          {' '}
          <img src="/images/trash.svg" className="w-[24px]" alt="icon" />{' '}
        </a>
      ) : (
        <div className="w-[24px] ml-[10px] md:ml-[40px]" />
      )}
    </MenuItem>
  )
}

export default function CurrencyList({
  currencies,
  allBalances,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  showSendWithSwap,
  height,
  fixed,
  fromCustom,
  setInactiveTokens,
  setTokenImport
}: {
  currencies: (Currency | IType)[]
  selectedCurrency: Currency
  allBalances: { [tokenAddress: string]: CurrencyAmount }
  onCurrencySelect: (currency: Currency) => void
  otherCurrency: Currency
  showSendWithSwap?: boolean
  height?: number | string
  fixed?: boolean
  fromCustom?: boolean
  setInactiveTokens?: Dispatch<SetStateAction<IInactiveToken[]>>
  setTokenImport: React.Dispatch<React.SetStateAction<IInactiveToken>>
}) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const allTokens = useAllTokens()
  const defaultTokens = useDefaultTokenList()
  const addToken = useAddUserToken()
  const removeToken = useRemoveUserAddedToken()
  const ETHBalance = useETHBalances([account])[account]

  const onImportToken = async (currency: IInactiveToken) => {
    if (setInactiveTokens) {
      setInactiveTokens(prevTokens => prevTokens.filter(item => item?.address !== currency?.address))
    }
    let token: any = currency
    if (currency?.decimals) {
      addToken(currency as any)
    } else {
      const erc20Contract = getContract(currency.address, ERC20_ABI, library)
      const decimals = await erc20Contract.decimals()
      addToken({
        ...currency,
        decimals
      } as any)
      token = {
        ...currency,
        decimals
      }
    }

    if (onCurrencySelect) {
      onCurrencySelect(
        new ExendToken(token.chainId, token.address, token.decimals, token.symbol, token.name, token.logoURI)
      )
    }
  }

  const handleAddToken = async (currency: IInactiveToken) => {
    if (currency.logoURI) {
      onImportToken(currency)
    } else {
      setTokenImport(currency)
    }
  }

  return (
    <>
      <FixedSizeList
        width="100%"
        height={height || 500}
        itemCount={currencies.length}
        itemSize={56}
        style={!height && { flex: 1 }}
        itemKey={index => currencyKey(currencies[index] as any)}
        className={classNames('scroll', fixed ? 'scrollFixed' : `!h-[${height}]px`)}
        itemData={{
          currencies,
          defaultTokens,
          allTokens,
          ETHBalance,
          selectedCurrency,
          allBalances,
          onCurrencySelect,
          otherCurrency,
          chainId,
          theme,
          showSendWithSwap,
          fixed,
          account,
          removeToken,
          addToken: handleAddToken,
          fromCustom
        }}
      >
        {CurrencyRow}
      </FixedSizeList>
    </>
  )
}
