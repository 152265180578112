import React, { useState, useRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import Row, { RowBetween, RowFixed } from '../Row'

import { darken } from 'polished'
import { useGasPrices, useGasType, useHideSlippageWarning, useIsUseGasless } from '../../state/user/hooks'
import { BigNumber } from 'ethers'
import SwitchField from '../SwitchField/SwitchField'
import { formatStringToNumber } from '../../utils'
import { ChainId } from '@safemoon/sdk'
import { useActiveWeb3React } from '../../hooks'
import { useSupportedGasless } from '../../hooks/0xApis'
import { toast } from 'react-toastify'
import { PairState } from '../../data/Reserves'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

enum DeadlineError {
  InvalidInput = 'InvalidInput'
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text3};
  align-items: center;
  height: 30px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  width: auto;
  min-width: 62px;
  outline: none;
  transition: 0.3s ease all;
  :hover {
  }
  :focus {
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 0;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) => active && theme.primaryMain} !important;
  color: ${({ active, theme }) => (active ? theme.black : theme.inputColor)} !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // width: calc((100% - 16px) / 3);
    margin-bottom: 0;
    flex-grow: 1;
    
    &.last-child {
      margin-right: 0;
    }
  `};
`

const GasOption = styled(Option)`
  flex-grow: 1;

  &:last-child {
    margin-right: 0;
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.bgDark};
  font-size: 16px;
  height: 40px;
  border-radius: 20px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: left;
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  background: ${({ theme }) => theme.bgDark} !important;
  background-color: ${({ theme }) => theme.bgDark} !important;
  height: 40px;
  border-radius: 20px;
  position: relative;
  padding: 0 15px;
  flex: 1;
  border: ${({ theme, active, warning }) => active && `2px solid ${warning ? theme.red1 : theme.primaryMain}`};
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primaryMain)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 20px;
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  margin-right: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
  gasPrice: string
  setGasPrice: (gasPrice: string, gasPriceType: string) => void
  isBridge?: boolean
  pairState?: PairState
}

export default function SlippageTabs({
  rawSlippage,
  setRawSlippage,
  deadline,
  setDeadline,
  // gasPrice,
  setGasPrice,
  isBridge,
  pairState
}: SlippageTabsProps) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const gasPrices = useGasPrices()
  const gasType = useGasType()

  const [hideSlippageWarning, handleHideSlippageWarning, handleShowSlippageWarning] = useHideSlippageWarning()
  const [isUseGasless, toggleIsUseGasless] = useIsUseGasless()

  const isSupportedGasless = useSupportedGasless()

  const inputRef = useRef<HTMLInputElement>()

  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput
  let slippageError: SlippageError
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  }

  let deadlineError: DeadlineError
  if ((deadlineInput !== '' && !deadlineInputIsValid) || +deadlineInput > 60) {
    deadlineError = DeadlineError.InvalidInput
  }

  function parseCustomSlippage(event) {
    setSlippageInput(event.target.value)

    let valueAsIntFromRoundedFloat: number
    try {
      valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(event.target.value) * 100).toFixed(2).toString())
    } catch {}

    if (
      typeof valueAsIntFromRoundedFloat === 'number' &&
      !Number.isNaN(valueAsIntFromRoundedFloat) &&
      valueAsIntFromRoundedFloat < 5000
    ) {
      setRawSlippage(valueAsIntFromRoundedFloat)
    }
  }

  function parseCustomDeadline(event) {
    setDeadlineInput(event.target.value)

    let valueAsInt: number
    try {
      valueAsInt = Number.parseInt(event.target.value) * 60
    } catch {}

    if (typeof valueAsInt === 'number' && !Number.isNaN(valueAsInt) && valueAsInt > 0) {
      setDeadline(valueAsInt)
    }
  }

  return (
    <AutoColumn gap="lg">
      <AutoColumn gap={'sm'}>
        <RowBetween style={{ height: 24, marginBottom: '8px' }}>
          <TYPE.black fontWeight={400} fontSize={16} color={theme.textLabel}>
            {t('transactionSpeed')}
          </TYPE.black>
          <QuestionHelper text={t('adjustYourGasPrice')} />
        </RowBetween>
        <RowBetween
          style={{
            flexWrap: 'wrap',
            height: '40px',
            backgroundColor: theme.bgDark,
            padding: '5px',
            borderRadius: '20px'
          }}
        >
          <GasOption
            onClick={() => {
              setGasPrice(gasPrices.default, 'default')
            }}
            active={gasType === 'default'}
          >
            Standard ({formatStringToNumber(gasPrices.default / 10 ** 9, gasPrices.default / 10 ** 9 < 0.01 ? 4 : 2)})
          </GasOption>
          <GasOption
            onClick={() => {
              setGasPrice(gasPrices.fast, 'fast')
            }}
            active={gasType === 'fast'}
          >
            Fast ({formatStringToNumber(gasPrices.fast / 10 ** 9, gasPrices.fast / 10 ** 9 < 0.01 ? 4 : 2)})
          </GasOption>
          <GasOption
            onClick={() => {
              setGasPrice(gasPrices.instant, 'instant')
            }}
            active={gasType === 'instant'}
          >
            Rapid ({formatStringToNumber(gasPrices.instant / 10 ** 9, gasPrices.instant / 10 ** 9 < 0.01 ? 4 : 2)})
          </GasOption>
        </RowBetween>
      </AutoColumn>

      {!isBridge && (
        <>
          <AutoColumn gap="sm">
            <RowBetween style={{ height: 24, marginBottom: '8px' }}>
              <TYPE.black fontWeight={400} fontSize={16} color={theme.textLabel}>
                {t('slippageTolerance')}
              </TYPE.black>
              <QuestionHelper text={t('yourTXWill')} />
            </RowBetween>
            <RowBetween>
              <Row
                style={{
                  height: '40px',
                  backgroundColor: theme.bgDark,
                  padding: '5px',
                  borderRadius: '20px',
                  width: 'auto',
                  marginRight: '5px'
                }}
              >
                <Option
                  onClick={() => {
                    setSlippageInput('')
                    setRawSlippage(10)
                  }}
                  active={rawSlippage === 10}
                >
                  0.1%
                </Option>
                <Option
                  onClick={() => {
                    setSlippageInput('')
                    setRawSlippage(50)
                  }}
                  active={rawSlippage === 50}
                >
                  0.5%
                </Option>
                <Option
                  className={'last-child'}
                  onClick={() => {
                    setSlippageInput('')
                    setRawSlippage(100)
                  }}
                  active={rawSlippage === 100}
                >
                  1%
                </Option>
              </Row>

              <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
                <RowBetween style={{ height: '100%' }}>
                  {!!slippageInput &&
                  (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                    <SlippageEmojiContainer>
                      <span role="img" aria-label="warning">
                        ⚠️
                      </span>
                    </SlippageEmojiContainer>
                  ) : null}
                  <Input
                    ref={inputRef}
                    placeholder={(rawSlippage / 100).toFixed(2)}
                    value={slippageInput}
                    onBlur={() => {
                      parseCustomSlippage({ target: { value: (rawSlippage / 100).toFixed(2) } })
                      window.scrollTo(0, 0)
                    }}
                    onChange={parseCustomSlippage}
                    color={!slippageInputIsValid ? 'red' : ''}
                  />
                  %
                </RowBetween>
              </OptionCustom>
            </RowBetween>
            {!!slippageError && (
              <RowBetween
                style={{
                  fontSize: '16px',
                  paddingTop: '10px',
                  color: slippageError === SlippageError.InvalidInput ? 'red' : '#F84BAD'
                }}
              >
                {slippageError === SlippageError.InvalidInput
                  ? t('enterAValid')
                  : slippageError === SlippageError.RiskyLow
                  ? t('yourTXMayFail')
                  : t('yourTXMayFront')}
              </RowBetween>
            )}
          </AutoColumn>

          <div className={'flex items-center mt-[0px]'}>
            <TYPE.black fontWeight={400} fontSize={16} color={theme.textLabel} marginRight="14px" className="flex-1">
              Slippage Popup:
            </TYPE.black>
            <div className="flex-1">
              <SwitchField
                value={!hideSlippageWarning}
                onChange={val => {
                  if (val) {
                    handleShowSlippageWarning()
                  } else {
                    handleHideSlippageWarning()
                  }
                }}
                size="sm"
              />
            </div>
          </div>

          <div className={'flex items-center mt-[0px]'}>
            <TYPE.black fontWeight={400} fontSize={16} color={theme.textLabel} marginRight="14px" className="flex-1">
              Use gasless:
            </TYPE.black>
            <div className="flex-1">
              <SwitchField
                value={isSupportedGasless && pairState === PairState.NOT_EXISTS ? isUseGasless : false}
                onChange={() => {
                  if (isSupportedGasless) {
                    if (pairState === PairState.EXISTS) {
                      toast('Not available for this pair.', {
                        type: 'error',
                        theme: 'dark'
                      })
                    } else {
                      toggleIsUseGasless()
                    }
                  } else {
                    toast('Unsupported on this chain', {
                      type: 'error',
                      theme: 'dark'
                    })
                  }
                }}
                size="sm"
              />
            </div>
          </div>

          <AutoColumn gap="sm">
            <RowBetween>
              <TYPE.black fontSize={16} fontWeight={400} color={theme.textLabel}>
                {t('transactionDeadline')}
              </TYPE.black>
              <QuestionHelper text={t('yourTXWill2')} />
            </RowBetween>
            <RowFixed style={{ width: '100%', marginBottom: '10px' }}>
              <OptionCustom style={{ width: '100%' }} tabIndex={-1} active={true}>
                <RowBetween style={{ height: '100%' }}>
                  <Input
                    color={!!deadlineError ? 'red' : undefined}
                    onBlur={() => {
                      parseCustomDeadline({ target: { value: (deadline > 3600 ? 60 : deadline / 60).toString() } })
                      window.scrollTo(0, 0)
                    }}
                    placeholder={(deadline / 60).toString()}
                    value={deadlineInput}
                    onChange={parseCustomDeadline}
                  />
                  <TYPE.body style={{ paddingRight: '8px' }} fontSize={14}>
                    {t('minutes')}
                  </TYPE.body>
                </RowBetween>
              </OptionCustom>
            </RowFixed>
            {deadline > 3600 && (
              <p style={{ fontSize: '12px', color: 'red', marginBottom: 0, marginTop: '-4px' }}>
                The limit maximum is 60 minutes
              </p>
            )}
          </AutoColumn>
        </>
      )}
    </AutoColumn>
  )
}
