import React from 'react'
import { Text } from 'rebass'
import Modal from '../Modal'
import { Wrapper } from './styleds'
import { RowBetween } from '../Row'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { ButtonLight, ButtonOutlined, ButtonPrimary } from '../Button'

type ConfirmationModalProps = {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
}

const Section = styled(AutoColumn)`
  padding: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 16px;
  `};
`
export default function Confirm0xSwap({ isOpen, onDismiss, onConfirm }: ConfirmationModalProps) {
  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <Section>
          <RowBetween>
            <div />
            <div onClick={onDismiss} className="cursor-pointer">
              <img className="cursor-pointer w-[24px]" src="/images/close.svg" alt="close" />
            </div>
          </RowBetween>

          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={700} fontSize={24} marginBottom="0px">
              Confirmation
            </Text>
            <Text fontSize={16} color="#ffffff" textAlign="center" marginBottom={'24px'}>
              This will execute the trade on the blockchain. Are you sure you wish to proceed?
            </Text>
            <div className="flex items-center w-full">
              <div className="mr-[10px] flex-1">
                <ButtonOutlined onClick={onDismiss} className="!w-[100%]">
                  Cancel
                </ButtonOutlined>
              </div>
              <div className="flex-1">
                <ButtonPrimary onClick={onConfirm} className="!w-[100%]">
                  Confirm
                </ButtonPrimary>
              </div>
            </div>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}
