import React from 'react'
import { warningSeverity0xApi } from '../../utils/prices'
import { ErrorText } from './styleds'
import { formatStringToNumber } from '../../utils'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact0xApi({ priceImpact = 0 }: { priceImpact?: number }) {
  return (
    <ErrorText fontWeight={400} fontSize={14} severity={warningSeverity0xApi(priceImpact)}>
      {priceImpact <= 0.01 ? '<0.01%' : `${formatStringToNumber(`${priceImpact}`)}%`}
    </ErrorText>
  )
}
